import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 21",
  week: "Semana 3",
  month: "abr",
  day: "03",
  monthNumber: "04",
  date: "2020-04-03",
  path: "/cronologia/semana-03#dia-03-abr",
};

const Day21 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText>
          El Consejo Superior de Investigaciones Científicas (CSIC) impulsa un{" "}
          <strong>
            proyecto para predecir la evolución del nuevo coronavirus
          </strong>
          . El estudio comparará los genomas del coronavirus de pacientes
          infectados a partir de datos microbiológicos de hospitales de toda
          España.
        </ModText>
        <ModAnimation svg="/images/anim/03_abr_predecir_corona.svg" />
        <ModText>
          El proyecto se integra en la nueva Plataforma Temática
          Interdisciplinar Salud Global, y los datos generados serán depositados
          en repositorios públicos, así como en la plataforma global NextStrain.
          Entre las empresas que han colaborado con el proyecto, se encuentra{" "}
          <strong>Aena</strong>, que{" "}
          <strong>
            ha donado dos millones de euros para investigar la inmunización de
            la población
          </strong>
          .
        </ModText>
        <ModText>
          El Ministerio de Asuntos Exteriores, Unión Europea y Cooperación ha
          puesto en marcha un
          <a
            href="https://registroviajeros.exteriores.gob.es/Aloja.html"
            target="_blank" 
 rel="noopener noreferrer"
          >
            servicio solidario de urgencia “ALOJA”
          </a>
          , para asistir a los españoles que se encuentran bloqueados en el
          extranjero. El objetivo es facilitar el contacto entre ciudadanos
          españoles que residen en el exterior y que deseen ofrecer alojamiento
          a viajeros españoles que aún no han podido regresar a España, y que
          necesitan una solución habitacional temporal.
        </ModText>
        <ModImage
          src="/images/svg/03_abr_aena.svg"
          alt="servicio solidario de urgencia ALOJA"
        />
        <ModText>
          Por otra parte, con las llegadas de los próximos vuelos a lo largo de
          este fin de semana, ya serán 20.000 viajeros españoles los que han
          podido regresar a España gracias a las gestiones de nuestras Embajadas
          y Consulados en el mundo.
        </ModText>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-03"} />
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.mitma.gob.es/transporte-terrestre/profesionales-transporte/distribucion-mascarillas-ambito-transporte-terrestre"
            name="Distribución de las mascarillas en el ámbito del
            transporte terrestre"
          />
          <ReferenceRow
            link="https://registroviajeros.exteriores.gob.es/Aloja.html"
            name="Servicio de alojamiento solidario para viajeros"
          />
        </ModReferenceList>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 117.710
          casos (7.472 más que el día anterior), 10.935 fallecidos (9,3% de los
          casos positivos) y 30.513 curados (25,9% de los casos positivos). El
          total de personas en UCI asciende a 6.416.
        </ModText>
        <ModTwoCols
          src="/images/svg/03_abr_unidades.svg"
          alt="se están fabricando respiradores y test, y hay empresas trabajando en fases de homologación"
          small={true}
        >
          Desde el pasado 10 de marzo,{" "}
          <strong>
            el Gobierno ha distribuido casi 36 millones de unidades de material
          </strong>
          entre las comunidades autónomas. Asimismo, también{" "}
          <strong>
            ha impulsado la producción nacional de material sanitario
          </strong>
          con el objetivo de combatir el virus y crear una reserva estratégica.
          Por ahora, ya se están fabricando respiradores y test, y hay empresas
          trabajando en fases de homologación.
        </ModTwoCols>
        <ModText>
          En línea con lo anterior, el Ministerio de Sanidad ha comunicado que
          esta semana se ha consolidado un suministro regular de EPIs, tests de
          diagnóstico y respiradores, provenientes de China.
        </ModText>
        <ModText>
          <strong>La Comunidad Autónoma de Asturia</strong>s, por su parte, ha
          iniciado el{" "}
          <strong>
            reciclaje de más de medio millón de mascarillas al día
          </strong>
          para personal sanitario de centros de toda España.
        </ModText>
        <ModTwoCols
          src="/images/svg/03_abr_sector_transporte.svg"
          alt="reparto a través de la red de oficinas de Correos"
          small={true}
        >
          El Ministerio de Transportes, Movilidad y Agenda Urbana (Mitma) hace
          públicas las instrucciones para la
          <a
            href="https://www.mitma.gob.es/transporte-terrestre/profesionales-transporte/distribucion-mascarillas-ambito-transporte-terrestre"
            target="_blank" 
 rel="noopener noreferrer"
          >
            distribución de mascarillas de protección entre los trabajadores del
            sector del transporte terrestre
          </a>
          . La distribución comenzará la semana que viene y se llevará a cabo a
          través de la red de oficinas de Correos.
        </ModTwoCols>
        <ModText>
          El reparto se hará a más de 162.000 empresas y autónomos del sector,
          incluyendo entre ellos a los que realizan transporte público de
          mercancías por carretera, transporte público de viajeros por autobús,
          servicios de taxi y VTC y transporte sanitario, así como a las
          empresas privadas de transporte de mercancías por ferrocarril.
        </ModText>
        <ModImage
          src="/images/svg/03_abr_sector_transporte_todo_el_ancho.svg"
          alt="suministro de medicamentos a los hospitales durante la Semana Santa"
        />
        <ModText>
          Asimismo, el sector de transporte por carretera ha comunicado su
          compromiso para mantener el{" "}
          <strong>
            suministro de medicamentos a los hospitales durante la Semana Santa
          </strong>
          .
        </ModText>
        <ModTwoCols
          src="/images/svg/03_abr_mayores_tecnologia.svg"
          alt='iniciativa solidaria “Conectad@s"'
          small={true}
        >
          <strong>Más de 6.000 mayores</strong> de 86 residencias{" "}
          <strong>se benefician de la iniciativa solidaria “Conectad@s”</strong>
          , de Ineco. Gracias a la donación de tablets por parte de la empresa,
          podrán comunicarse con sus seres queridos durante el período de
          aislamiento.
        </ModTwoCols>
        <ModText>
          Las <strong>Fuerzas Armadas apoyan a los bancos de alimentos</strong>
          desde el inicio de la Operación Balmis (misión de las Fuerzas Armadas
          frente al Covid-19 que comenzó el pasado 14 de marzo). Entre los
          lugares y actuaciones destacadas se encuentra el transporte y
          distribución de alimentos en Madrid.
        </ModText>
        <ModText>
          La Organización Nacional de Trasplantes y el Registro de Donantes de
          Médula Ósea cuentan con la colaboración de la Guardia Civil para hacer
          posible el <strong>operativo especial</strong> que pusieron en marcha
          el pasado 16 de marzo para <strong>donaciones de médula ósea</strong>.
          Hasta el momento, España ha recibido 17 donaciones y ha enviado tres a
          otros países.
        </ModText>
        <ModText>
          El Ministerio de Agricultura, Pesca y Alimentación establecerá{" "}
          <strong>
            medidas de apoyo directo para los sectores de ovino y caprino
          </strong>
          , dado que su producción es marcadamente estacional y vinculada a su
          consumo en restaurantes y hostelería.
        </ModText>
        <ModImage
          src="/images/svg/03_abr_voluntarios_proteccion_civil.svg"
          alt="Protección Civil cuenta con la participación de Agrupaciones de Voluntarios"
        />
        <ModText>
          El Sistema Nacional de Protección Civil cuenta con la participación de
          Agrupaciones de Voluntarios que colaboran especialmente en el ámbito
          local.
        </ModText>
        <ModText>
          Entre ellos, se encuentran más de{" "}
          <strong>20.000 voluntarios de Protección Civil</strong> para labores
          de distribución de alimentos o para colaborar en la instalación de
          hospitales de campaña, y{" "}
          <strong>
            más de 600 ambulancias y 12.000 voluntarios de Cruz Roja
          </strong>
          .
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day21;
